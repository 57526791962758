const memberApi = {
    // 会员列表
    memberList: '/member/list',
    //会员来源
    memberSource: '/setting/member/source',
    // 会员等级
    memberLevel: '/setting/member/level/list',
    //会员拉黑
    memberForbidden: '/member/forbidden',
    //会员详情
    memberDetail: '/member/getById',
    //收货人地址
    memberSite: '/member/address/list',

    //会员等级列表
    levelList: '/setting/member/level/list',
    //会员等级删除
    levelDel: '/setting/member/level/delete',
    //会员等级新增
    levelAdd: '/setting/member/level/add',
    //会员等级编辑
    levelEdit: '/setting/member/level/edit',
}


export default memberApi